<template>
  <v-dialog
    @click:outside="closeDialog"
    :value="isDialogOpen"
    transition="dialog-top-transition"
    max-width="75%"
    :scrollable="false"
    style="overflow: hidden"
  >
    <v-card>
      <!-- <v-toolbar fixed color="titlebg" class="title" dark>
        <div class="d-flex align-center justify-space-between" style="width: 100%">
          <h4 class="title">{{ item.title }}</h4>
          <div class="priority-item">
            <v-btn icon>
              <img width="20" :src="item && getImgUrl(item)" />
            </v-btn>
          </div>
        </div>
      </v-toolbar> -->
      <v-toolbar fixed color="titlebg" class="title" dense dark>
        <v-toolbar-title>Task : {{ item.title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon  @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <div class="px-4 py-4">
        <v-row>
          <v-col>
            <v-card class="py-2 px-4 mb-4">
              <h5 class="mb-2">Responsible Users</h5>
              <v-chip
                v-for="(user, index) in item && item.responsibleUsers"
                :key="index"
                class="font-weight-semibold mr-2 mb-2"
                link
                medium
                color="primary"
              >
                {{ user.responsibleUser.fullName ?? user.responsibleUser.name}}
              </v-chip>
            </v-card>
            <v-card class="py-2 px-4 mb-4">
              <h5 class="mb-2">Description</h5>

              <p class="p-0 m-0">
                {{ item && item.description }}
              </p>
            </v-card></v-col
          >
          <v-col>
            <v-card-text
              style="max-height: 500px; overflow: auto"
              class="py-0 elevation-2 rounded-lg"
            >
              <v-timeline align-top dense>
                <v-timeline-item
                  v-for="(event, index) in item && item.events"
                  :key="event.id"
                  :color="index % 2 ? 'accent' : 'greeny'"
                  small
                >
                  <v-row class="pt-1">
                    <v-col cols="3">
                      <strong>{{ event.datetime }}</strong>
                    </v-col>
                    <v-col>
                      <strong>{{ event.user.fullName ?? event.user.name }}</strong>
                      <div class="text-caption">
                        {{ event.type.split("_").join(" ") }}
                      </div>
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </v-timeline>
            </v-card-text>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["isDialogOpen", "item"],
  data: () => ({
    priorities: [
      {
        id: 5,
        name: "Highest",
        image: "highest.svg"
      },
      {
        id: 4,
        name: "High",
        image: "high.svg"
      },
      {
        id: 3,
        name: "Medium",
        image: "medium.svg"
      },
      {
        id: 2,
        name: "Low",
        image: "low.svg"
      },
      {
        id: 1,
        name: "Lowest",
        image: "lowest.svg"
      }
    ]
  }),
  methods: {
    closeDialog() {
      this.$emit("onClose");
    },
    getImgUrl(item) {
      const current_priority = this.priorities.find(r => r.id === item.priority);
      let imgUrl = current_priority
        ? "priority-icons/" + current_priority.image
        : "priority-icons/medium.svg";

      return require("@/assets/" + imgUrl);
    }
  }
};
</script>

<style lang="scss" scoped>
.priority-item {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.v-dialog > .v-card > .v-toolbar {
  position: sticky;
  top: 0;
  z-index: 999;
}
.v-dialog > .v-card > .v-card__actions {
  background: white;
  position: sticky;
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  bottom: 0;
  z-index: 999;
}
</style>

<template>
  <v-dialog
    @click:outside="closeDialog"
    :value="isDialogOpen"
    transition="dialog-top-transition"
    max-width="80%"
    :scrollable="false"
    style="overflow: hidden"
  >
    <v-card>
      <v-toolbar fixed color="titlebg" class="title" dense dark>
        <v-toolbar-title>Create New Task</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <div class="px-7 py-7 rounded-lg">
        <v-row>
          <v-col cols="12">
            <!-- <v-row>
              <v-col>
                <v-select
                  outlined
                  class="align-stretch font-weight-bold"
                  v-model="createTaskBody.tenants"
                  :items="tenants"
                  item-text="name"
                  return-object
                  :menu-props="{ maxHeight: '400' }"
                  label="Select tenants"
                  multiple
                  hide-details
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index < 3">
                      <span>{{ item.name }}</span>
                    </v-chip>
                    <span v-if="index === 3" class="grey--text text-caption">
                      (+{{ selectedTenants.length - 3 }})
                    </span>
                  </template>
                </v-select>
              </v-col>
            </v-row> -->
            <v-row>
              <v-col>
                <v-select
                  outlined
                  class="align-stretch font-weight-bold"
                  v-model="createTaskBody.occurrence"
                  :items="occurrences"
                  item-text="Occurrence"
                  :menu-props="{ maxHeight: '400' }"
                  label="Select Occurrence"
                  dense
                  :persistent-hint="false"
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="2">
                <vue-timepicker
                  v-model="createTaskBody.time"
                  format="HH:mm"
                ></vue-timepicker>
                <!-- <v-select
                  outlined
                  class="align-stretch font-weight-bold"
                  v-model="createTaskBody.priority"
                  :items="priorities"
                  item-text="Priority"
                  item-value="id"
                  dense
                  :menu-props="{ maxHeight: '400' }"
                  label="Select Priority"
                  :persistent-hint="false"
                  hide-details
                >
                  <template v-slot:selection="{ item }">
                    <img
                      class="mr-2"
                      width="20"
                      :src="getImgUrl('priority-icons/' + item.image)"
                    />{{ item.name }}
                  </template>
                  <template v-slot:item="{ item }">
                    <img
                      class="mr-2"
                      :src="getImgUrl('priority-icons/' + item.image)"
                      width="20"
                    />{{ item.name }}
                  </template>
                </v-select> -->
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Title"
                  outlined
                  required
                  dense
                  v-model="createTaskBody.title"
                ></v-text-field>
              </v-col>
              <!-- <v-col> <v-menu
                  ref="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="createTaskBody.time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                  label="Time (in UTC)"
                >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      dense
                      v-model="createTaskBody.time"
                      readonly
                      full-width
                      v-bind="attrs"
                      v-on="on"
                      label="Time (in UTC)"
                    ></v-text-field>
                  </template>

                <v-time-picker
                    v-model="createTaskBody.time"
                    :textFieldProps="textFieldProps"
                    @click:minute="$refs.menu.save(createTaskBody.time)"
                    label="Time (in UTC)"
                  />
                </v-menu>
              </v-col> -->
            </v-row>
            <v-textarea
              outlined
              hide-details
              label="Description"
              dense
              clearable
              v-model="createTaskBody.description"
            />
          </v-col>

          <v-col cols="12">
            <v-card class="mx-auto bg-danger overflow-hidden" max-width="100%">
              <v-toolbar fixed color="titlebg" class="title" dense dark flat
                >Responsible Users</v-toolbar
              >
              <v-text-field
                class="mx-4 my-4"
                ref="search"
                dense
                v-model="userSearchQuery"
                full-width
                hide-details
                label="Search"
                outlined
                :loading="searching"
                prepend-inner-icon="mdi-account-search"
                single-line
              ></v-text-field>

              <v-row class="pl-5 pr-5">
                <v-col>
                  <h4 class="px-3 mb-0 accent--text">Search Result</h4>
                  <v-list dense style="height: 300px; overflow-y: scroll">
                    <div v-if="users.length > 0">
                      <template v-for="item in users">
                        <v-list-item
                          dense
                          v-if="
                            !createTaskBody.responsibleUsers.find(
                              (user) => user.responsibleUser.id === item.id
                            )
                          "
                          :key="item.text"
                          :disabled="loading"
                          @click="
                            createTaskBody.responsibleUsers.push({
                              responsibleUser: { id: item.id },
                              text: item.fullName ?? item.name,
                            })
                          "
                        >
                          <v-list-item-avatar size="20">
                            <v-icon
                              :disabled="false"
                              dense
                              v-text="item.icon"
                            ></v-icon>
                          </v-list-item-avatar>
                          <v-list-item-title
                            v-text="item.text"
                          ></v-list-item-title>
                        </v-list-item>
                      </template>
                    </div>

                    <!--<v-progress-circular
                      v-else
                      indeterminate
                      color="primary"
                    ></v-progress-circular>-->
                  </v-list>
                </v-col>
                <v-col>
                  <h4 class="px-3 mb-0">Selected Users</h4>
                  <v-list style="height: 300px; overflow-y: scroll">
                    <draggable
                      class="draggable-list"
                      :list="createTaskBody.responsibleUsers"
                      group="my-group"
                    >
                      <v-list-item
                        v-for="(
                          selection, i
                        ) in createTaskBody.responsibleUsers"
                        :key="selection.text"
                        style="cursor: pointer"
                        :style="{
                          background: `rgba(243,122,98,${
                            20 / (i + 0.01) / 40
                          })`,
                        }"
                        class="list-item shrink"
                      >
                        <v-list-item-avatar>
                          <v-icon
                            :color="`rgba(${255 / (i + 1)},${255 / (i + 1)},${
                              255 / (i + 1)
                            })`"
                            :disabled="false"
                            v-text="'mdi-account'"
                          ></v-icon>
                        </v-list-item-avatar>
                        <v-list-item-title
                          :style="{
                            color: `rgba(${255 / (i + 1)},${255 / (i + 1)},${
                              255 / (i + 1)
                            })`,
                          }"
                          >{{ selection.text }}
                        </v-list-item-title>
                        <v-btn
                          icon
                          @click="createTaskBody.responsibleUsers.splice(i, 1)"
                          ><v-icon>mdi-close</v-icon></v-btn
                        >
                      </v-list-item>
                    </draggable>
                  </v-list>
                </v-col>
              </v-row>

              <!--<v-divider></v-divider>-->

              <!--<v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :disabled="!selected.length"
                :loading="loading"
                color="purple"
                text
                @click="next"
              >
                Next
              </v-btn>
            </v-card-actions>-->
            </v-card>
          </v-col>
        </v-row>
      </div>
      <v-card-actions class="justify-end pb-4 pr-6 px-4">
        <v-btn color="primary" @click="closeDialog">Close</v-btn>
        <v-btn
          dark
          color="accent"
          :loading="submitLoader"
          @click="createTask"
          >{{ editItem ? "Update" : "Create" }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import DateTimePicker from "../../../../components/common/DateTimePicker.vue";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import { debounce } from "@/util/helpers";
import TrainplanetApi from "@/util/trainplanet.api";
import { mapGetters } from "vuex";
import draggable from "vuedraggable";

export default {
  props: ["editItem", "isDialogOpen"],
  components: { DateTimePicker, draggable, VueTimepicker },

  data: () => ({
    userSearchQuery: "",
    searching: false,
    selectedTenants: [],
    textFieldProps: {
      outlined: true,
    },
    list1: [
      { name: "Drag Me!" },
      { name: "asdf Me!" },
      { name: "Drcvbxcvbag Me!" },
    ],
    submitLoader: false,
    createTaskBody: {
      title: "",
      description: "",
      occurrence: "daily",
      time: "00:00",
      responsibleUsers: [],
      priority: 0,
      tenants: [],
    },
    occurrences: ["daily", "weekly", "monthly", "yearly"],
    priorities: [
      {
        id: 5,
        name: "Highest",
        image: "highest.svg",
      },
      {
        id: 4,
        name: "High",
        image: "high.svg",
      },
      {
        id: 3,
        name: "Medium",
        image: "medium.svg",
      },
      {
        id: 2,
        name: "Low",
        image: "low.svg",
      },
      {
        id: 1,
        name: "Lowest",
        image: "lowest.svg",
      },
    ],
    items: [],
    loading: false,
    search: "",
    selected: [],
  }),
  mounted() {
    this.loading = true;
    this.getUsers();
  },
  computed: {
    ...mapGetters({
      tenants: "auth/userTenants",
    }),
    allSelected() {
      return this.selected.length === this.items.length;
    },
    users() {
      const search = this.search.toLowerCase();

      if (!search) return this.items;

      return this.items.filter((item) => {
        const text = item.text.toLowerCase();

        return text.indexOf(search) > -1;
      });
    },
    selections() {
      const selections = [];

      for (const selection of this.selected) {
        selections.push(selection);
      }

      return selections;
    },
  },
  watch: {
    editItem() {
      if (this.editItem) {
        this.createTaskBody = {
          ...this.editItem,
          responsibleUsers: this.editItem.responsibleUsers.map((item) => ({
            ...item,
            text: item.responsibleUser.fullName ?? item.responsibleUser.name, // we set text for select component !
          })),
        };
      }
    },
    selected() {
      this.search = "";
    },
    userSearchQuery: {
      deep: true,
      handler: debounce(function () {
        this.searching = true;
        this.getUsers();
      }, 200),
    },
  },
  methods: {
    getImgUrl(imgUrl) {
      return require("@/assets/" + imgUrl);
    },
    closeDialog() {
      this.$emit("onClose");
      this.createTaskBody = {
        title: "",
        description: "",
        occurrence: "daily",
        time: "00:00",
        responsibleUsers: [],
        priority: 0,
      };
    },
    next() {
      this.loading = true;
      setTimeout(() => {
        this.search = "";
        this.selected = [];
        this.loading = false;
      }, 2000);
    },
    async getUsers() {
      const res = await TrainplanetApi.getUsers(this.userSearchQuery);
      this.items = res.users.map((item) => ({
        ...item,
        text: item.fullName ?? item.name,
        icon: "mdi-account",
      }));
      this.searching = false;
      this.loading = false;
    },
    async createTask() {
      try {
        this.submitLoader = true;

        if (this.editItem) {
          const res = await TrainplanetApi.updateTask(this.createTaskBody.id, {
            ...this.createTaskBody,
            responsibleUsers: this.createTaskBody.responsibleUsers.map(
              (item, index) => ({
                ...item,
                position: index,
              })
            ),
          });
        } else {
          const res = await TrainplanetApi.addNewTask({
            ...this.createTaskBody,
            responsibleUsers: this.createTaskBody.responsibleUsers.map(
              (item, index) => ({
                ...item,
                position: index,
              })
            ),
          });
        }

        this.createTaskBody = {
          title: "",
          description: "",
          occurrence: "",
          time: new Date(),
          responsibleUsers: [],
          priority: 0,
        };

        this.closeDialog();
        this.$emit("created");
      } catch (error) {
        this.$store.dispatch("error", error.response.data.message);
      } finally {
        this.submitLoader = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 1px !important;
}
::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}
::-webkit-scrollbar-thumb {
  background: #888 !important;
  border-radius: 10px !important;
}
::-webkit-scrollbar-thumb:hover {
  background: rgb(138, 138, 138) !important;
}
.v-dialog > .v-card > .v-toolbar {
  position: sticky;
  top: 0;
  z-index: 999;
}
.v-dialog > .v-card > .v-card__actions {
  background: white;
  position: sticky;
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  bottom: 0;
  z-index: 999;
}
</style>

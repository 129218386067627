<template>
  <div>
    <header
      class="theme--light v-navigation-drawer elevation-1"
      style="height: 50px; border-radius: 5px; margin-bottom: 10px"
    >
      <div
        class="v-toolbar__content flex flex-row justify-space-between"
        style="height: 50px; padding: 25px"
      >
        <div class="v-toolbar__title">
          <h4 class="black--text">Tasks</h4>
        </div>
        <v-btn
          class="ml-4"
          style="height: 40px"
          dark
          small
          color="accent"
          @click="isDialogOpened = true"
          v-if="isBackOfficeAdmin"
        >
          Create New Task
        </v-btn>
      </div>
    </header>

    <!-- <div class="d-flex"> -->
    <!-- <v-select
        solo
        class="align-stretch font-weight-bold"
        v-model="selectedTenants"
        :items="tenants"
        item-text="name"
        dense
        chips
        item-value="id"
        :menu-props="{ maxHeight: '400' }"
        label="Select tenants"
        multiple
        :persistent-hint="false"
      >
        <template v-slot:selection="{ item, index }">
          <v-chip v-if="index < 3">
            <span>{{ item.name }}</span>
          </v-chip>
          <span v-if="index === 3" class="grey--text text-caption">
            (+{{ selectedTenants.length - 3 }})
          </span>
        </template>
      </v-select> -->

    <!-- </div> -->
    <create-task-modal
      @created="getTasks"
      @onClose="
        isDialogOpened = false;
        editItem = null;
      "
      :isDialogOpen="isDialogOpened"
      :editItem="editItem"
    />

    <div
      v-for="(occurrence, index) in Object.keys(tasks)"
      :key="index"
      class="mb-7"
    >
      <div v-if="tasks[occurrence].length > 0">
        <v-toolbar
          fixed
          :class="'text font-weight-bold ' + colorize(index + 1)"
          dense
          dark
          flat
          rounded
        >{{ occurrence.toString().toUpperCase() }}
        </v-toolbar>
        <v-data-table
          dense
          :headers="headers"
          :items="tasks[occurrence]"
          :loading="loading"
          class="elevation-1 rounded-lg mt-2"
          v-model="selected"
          :single-select="false"
          :footer-props="{
            'items-per-page-options': [20, 50, 100],
          }"
        >
          <template v-slot:[`item.title`]="{ item }">
            <task-item @onValueChange="onValueChange($event)" :item="item"/>
          </template>

          <template v-slot:[`item.time`]="{ item }">
            {{ item.time | parseTimeToLocal }}
          </template>

          <template v-slot:[`item.responsibleUsers`]="{ item }">
            <v-chip
              v-for="(user, index) in item.responsibleUsers"
              :key="index"
              class="font-weight-bold cliporder mr-1"
              :class="
                currentUserName === (user.responsibleUser.fullName ?? user.responsibleUser.name)
                  ? 'greeny white--text'
                  : ''
              "
              style="
                min-width: 10px;
                font-size: 12px;
                min-height: 27px;
                text-transform: capitalize;
              "
              link
              x-small
            >
              {{ user.responsibleUser.fullName ?? user.responsibleUser.name}}
            </v-chip>
          </template>

          <!-- <template v-slot:[`item.priority`]="{ item }">
            <img width="20" :src="item && getImgUrl(item)"/>
          </template> -->
          <template v-slot:[`item.signedBy`]="{ item }">
            {{
              item.isCompleted
                ? item.lastCompletedEvent && (item.lastCompletedEvent.user.fullName ?? item.lastCompletedEvent.user.name)
                : ""
            }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div v-if="isBackOfficeAdmin">
              <v-btn @click="view(item)" icon>
                <v-icon color="accent">mdi-eye</v-icon>
              </v-btn>
              <v-btn @click="openEditModal(item)" icon>
                <v-icon color="third">mdi-pencil</v-icon>
              </v-btn>
              <v-btn @click="deleteTask(item.id)" icon>
                <v-icon color="primary">mdi-delete</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </div>
    </div>

    <v-progress-linear
      v-if="loading"
      indeterminate
      color="#f4846e"
    ></v-progress-linear>

    <detail-dialog
      :item="editItem"
      v-if="editItem"
      @onClose="
        isDetailDialogOpen = false;
        editItem = null;
      "
      :isDialogOpen="isDetailDialogOpen"
    />
  </div>
</template>

<script>
import TrainplanetApi from "@/util/trainplanet.api";
import {mapGetters} from "vuex";
import CreateTaskModal from "./components/CreateTaskModal.vue";
import TaskItem from "./components/TaskItem.vue";
import DetailDialog from "./components/DetailDialog.vue";
import moment from "moment";

export default {
  components: {
    CreateTaskModal,
    TaskItem,
    DetailDialog,
  },
  filters: {
    parseTimeToLocal(time) {
      return moment.utc(time, "HH:mm").local().format("HH:mm");
    },
  },
  data: () => ({
    currentUserName: null,
    isDialogOpened: false,
    isDetailDialogOpen: false,
    loading: false,
    editItem: null,
    tasks: {},
    selectedTenants: [],
    checkbox: true,
    selected: [],
    priorities: [
      {
        id: 5,
        name: "Highest",
        image: "highest.svg",
      },
      {
        id: 4,
        name: "High",
        image: "high.svg",
      },
      {
        id: 3,
        name: "Medium",
        image: "medium.svg",
      },
      {
        id: 2,
        name: "Low",
        image: "low.svg",
      },
      {
        id: 1,
        name: "Lowest",
        image: "lowest.svg",
      },
    ],
    headers: [
      //{
      //  text: "",
      //  value: "check",
      //  align: "start",
      //},
      {
        text: "Title",
        value: "title",
        align: "start",
        width: 500,
      },
      {
        text: `Time (GMT +${moment().utcOffset() / 60})`,
        value: "time",
        align: "start",
      },
      {
        text: "Responsible Users",
        value: "responsibleUsers",
        align: "start",
        width: 500,
      },
      {
        text: "Signed",
        value: "signedBy",
        align: "start",
      },
      {
        text: "",
        value: "actions",
        align: "end",
      },
    ],
  }),
  mounted() {
    this.currentUserName = this.user.fullName ?? this.user.name;
    this.loading = true;
    this.$store.dispatch("loading", true);
    if (this.isThereAnyTenant) {
      this.selectedTenants = this.tenants.map((item) => item.id);
    }
    setInterval(() => {
      this.getTaskBackground();
    }, 10000);
  },
  watch: {
    tenants: {
      deep: true,
      handler: function (value) {
        this.selectedTenants = this.tenants.map((item) => item.id);
      },
    },
    selectedTenants: {
      deep: false,
      handler: function () {
        this.getTasks();
      },
    },
  },
  methods: {
    colorize(x) {
      switch (x) {
        case 1:
          return `primary`;
        case 2:
          return `third`;
        case 3:
          return `greeny`;
        case 4:
          return `accent`;
        default:
          return `accent`;
      }
    },
    // getImgUrl(item) {
    //   const current_priority = this.priorities.find(r => r.id === item.priority);
    //   let imgUrl = current_priority
    //     ? "priority-icons/" + current_priority.image
    //     : "priority-icons/medium.svg";

    //   return require("@/assets/" + imgUrl);
    // },
    view(item) {
      this.isDetailDialogOpen = true;
      this.editItem = item;
    },
    async onValueChange({value, id}) {
      this.loading = true;
      await this.$store.dispatch("loading", true);

      try {
        if (value) {
          await TrainplanetApi.completeTask(id);
        } else {
          await TrainplanetApi.incompleteTask(id);
        }

        await this.$store.dispatch("success", "You've successfully " + (value ? "completed" : "removed the completed status from") + " the task.");

        await this.getTasks();
      } catch (error) {
        await this.$store.dispatch(
          "error",
          error.response.data.message || error.message || error
        );
      } finally {
        this.loading = false;
        await this.$store.dispatch("loading", false);
      }
    },
    openEditModal(item) {
      this.editItem = item;
      this.isDialogOpened = true;
    },
    async getTasks() {
      let res;

      this.loading = true;
      await this.$store.dispatch("loading", true);

      try {
        res = await TrainplanetApi.getTasks(this.selectedTenants);
      } catch (error) {
        return await this.$store.dispatch(
          "error",
          error.response.data.message || error.message
        );
      } finally {
        this.loading = false;
        await this.$store.dispatch("loading", false);
      }

      if (res) {
        this.tasks = res.reduce(
          (acc, curr) => {
            acc[curr.occurrence].push(curr);
            return acc;
          },
          {daily: [], weekly: [], monthly: [], yearly: []}
        );
      }
    },

    async getTaskBackground() {
      let res;
      try {
        res = await TrainplanetApi.getTasks(this.selectedTenants);
      } catch (error) {
        return await this.$store.dispatch(
          "error",
          error.response.data.message || error.message
        );
      } finally {
      }

      if (res) {
        this.tasks = res.reduce(
          (acc, curr) => {
            acc[curr.occurrence].push(curr);
            return acc;
          },
          {daily: [], weekly: [], monthly: [], yearly: []}
        );
      }
    },
    async deleteTask(id) {
      this.$confirm("Are you sure you want to delete the task?", {
        color: "primary",
        title: "Delete task",
      }).then(async (res) => {
        if (!res) {
          this.checkbox = !this.checkbox;
        } else {
          try {
            await this.$store.dispatch("loading", true);
            this.loading = true;
            await TrainplanetApi.deleteTask(id);
            await this.getTasks();
          } catch (error) {
            await this.$store.dispatch("error", error.response.data.message);
          } finally {
            await this.$store.dispatch("loading", false);
            this.loading = false;
          }
        }
      });
    },
  },
  computed: {
    ...mapGetters({
      tenants: "auth/userTenants",
      user: "auth/user",
      isBackOfficeAdmin: "auth/isBackOfficeAdmin",
    }),
    isThereAnyTenant: function () {
      return this.tenants.length > 0;
    },
  },
};
</script>

<style lang="scss">
::-webkit-scrollbar {
  width: 6px !important;
}

::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

::-webkit-scrollbar-thumb {
  background: #888 !important;
  border-radius: 10px !important;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(138, 138, 138) !important;
}
</style>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "value": _vm.isDialogOpen,
      "transition": "dialog-top-transition",
      "max-width": "80%",
      "scrollable": false
    },
    on: {
      "click:outside": _vm.closeDialog
    }
  }, [_c('v-card', [_c('v-toolbar', {
    staticClass: "title",
    attrs: {
      "fixed": "",
      "color": "titlebg",
      "dense": "",
      "dark": ""
    }
  }, [_c('v-toolbar-title', [_vm._v("Create New Task")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('div', {
    staticClass: "px-7 py-7 rounded-lg"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', [_c('v-col', [_c('v-select', {
    staticClass: "align-stretch font-weight-bold",
    attrs: {
      "outlined": "",
      "items": _vm.occurrences,
      "item-text": "Occurrence",
      "menu-props": {
        maxHeight: '400'
      },
      "label": "Select Occurrence",
      "dense": "",
      "persistent-hint": false,
      "hide-details": ""
    },
    model: {
      value: _vm.createTaskBody.occurrence,
      callback: function ($$v) {
        _vm.$set(_vm.createTaskBody, "occurrence", $$v);
      },
      expression: "createTaskBody.occurrence"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('vue-timepicker', {
    attrs: {
      "format": "HH:mm"
    },
    model: {
      value: _vm.createTaskBody.time,
      callback: function ($$v) {
        _vm.$set(_vm.createTaskBody, "time", $$v);
      },
      expression: "createTaskBody.time"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Title",
      "outlined": "",
      "required": "",
      "dense": ""
    },
    model: {
      value: _vm.createTaskBody.title,
      callback: function ($$v) {
        _vm.$set(_vm.createTaskBody, "title", $$v);
      },
      expression: "createTaskBody.title"
    }
  })], 1)], 1), _c('v-textarea', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "label": "Description",
      "dense": "",
      "clearable": ""
    },
    model: {
      value: _vm.createTaskBody.description,
      callback: function ($$v) {
        _vm.$set(_vm.createTaskBody, "description", $$v);
      },
      expression: "createTaskBody.description"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "mx-auto bg-danger overflow-hidden",
    attrs: {
      "max-width": "100%"
    }
  }, [_c('v-toolbar', {
    staticClass: "title",
    attrs: {
      "fixed": "",
      "color": "titlebg",
      "dense": "",
      "dark": "",
      "flat": ""
    }
  }, [_vm._v("Responsible Users")]), _c('v-text-field', {
    ref: "search",
    staticClass: "mx-4 my-4",
    attrs: {
      "dense": "",
      "full-width": "",
      "hide-details": "",
      "label": "Search",
      "outlined": "",
      "loading": _vm.searching,
      "prepend-inner-icon": "mdi-account-search",
      "single-line": ""
    },
    model: {
      value: _vm.userSearchQuery,
      callback: function ($$v) {
        _vm.userSearchQuery = $$v;
      },
      expression: "userSearchQuery"
    }
  }), _c('v-row', {
    staticClass: "pl-5 pr-5"
  }, [_c('v-col', [_c('h4', {
    staticClass: "px-3 mb-0 accent--text"
  }, [_vm._v("Search Result")]), _c('v-list', {
    staticStyle: {
      "height": "300px",
      "overflow-y": "scroll"
    },
    attrs: {
      "dense": ""
    }
  }, [_vm.users.length > 0 ? _c('div', [_vm._l(_vm.users, function (item) {
    return [!_vm.createTaskBody.responsibleUsers.find(function (user) {
      return user.responsibleUser.id === item.id;
    }) ? _c('v-list-item', {
      key: item.text,
      attrs: {
        "dense": "",
        "disabled": _vm.loading
      },
      on: {
        "click": function ($event) {
          var _item$fullName;
          return _vm.createTaskBody.responsibleUsers.push({
            responsibleUser: {
              id: item.id
            },
            text: (_item$fullName = item.fullName) !== null && _item$fullName !== void 0 ? _item$fullName : item.name
          });
        }
      }
    }, [_c('v-list-item-avatar', {
      attrs: {
        "size": "20"
      }
    }, [_c('v-icon', {
      attrs: {
        "disabled": false,
        "dense": ""
      },
      domProps: {
        "textContent": _vm._s(item.icon)
      }
    })], 1), _c('v-list-item-title', {
      domProps: {
        "textContent": _vm._s(item.text)
      }
    })], 1) : _vm._e()];
  })], 2) : _vm._e()])], 1), _c('v-col', [_c('h4', {
    staticClass: "px-3 mb-0"
  }, [_vm._v("Selected Users")]), _c('v-list', {
    staticStyle: {
      "height": "300px",
      "overflow-y": "scroll"
    }
  }, [_c('draggable', {
    staticClass: "draggable-list",
    attrs: {
      "list": _vm.createTaskBody.responsibleUsers,
      "group": "my-group"
    }
  }, _vm._l(_vm.createTaskBody.responsibleUsers, function (selection, i) {
    return _c('v-list-item', {
      key: selection.text,
      staticClass: "list-item shrink",
      staticStyle: {
        "cursor": "pointer"
      },
      style: {
        background: `rgba(243,122,98,${20 / (i + 0.01) / 40})`
      }
    }, [_c('v-list-item-avatar', [_c('v-icon', {
      attrs: {
        "color": `rgba(${255 / (i + 1)},${255 / (i + 1)},${255 / (i + 1)})`,
        "disabled": false
      },
      domProps: {
        "textContent": _vm._s('mdi-account')
      }
    })], 1), _c('v-list-item-title', {
      style: {
        color: `rgba(${255 / (i + 1)},${255 / (i + 1)},${255 / (i + 1)})`
      }
    }, [_vm._v(_vm._s(selection.text) + " ")]), _c('v-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": function ($event) {
          return _vm.createTaskBody.responsibleUsers.splice(i, 1);
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1);
  }), 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('v-card-actions', {
    staticClass: "justify-end pb-4 pr-6 px-4"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_vm._v("Close")]), _c('v-btn', {
    attrs: {
      "dark": "",
      "color": "accent",
      "loading": _vm.submitLoader
    },
    on: {
      "click": _vm.createTask
    }
  }, [_vm._v(_vm._s(_vm.editItem ? "Update" : "Create"))])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('header', {
    staticClass: "theme--light v-navigation-drawer elevation-1",
    staticStyle: {
      "height": "50px",
      "border-radius": "5px",
      "margin-bottom": "10px"
    }
  }, [_c('div', {
    staticClass: "v-toolbar__content flex flex-row justify-space-between",
    staticStyle: {
      "height": "50px",
      "padding": "25px"
    }
  }, [_vm._m(0), _vm.isBackOfficeAdmin ? _c('v-btn', {
    staticClass: "ml-4",
    staticStyle: {
      "height": "40px"
    },
    attrs: {
      "dark": "",
      "small": "",
      "color": "accent"
    },
    on: {
      "click": function ($event) {
        _vm.isDialogOpened = true;
      }
    }
  }, [_vm._v(" Create New Task ")]) : _vm._e()], 1)]), _c('create-task-modal', {
    attrs: {
      "isDialogOpen": _vm.isDialogOpened,
      "editItem": _vm.editItem
    },
    on: {
      "created": _vm.getTasks,
      "onClose": function ($event) {
        _vm.isDialogOpened = false;
        _vm.editItem = null;
      }
    }
  }), _vm._l(Object.keys(_vm.tasks), function (occurrence, index) {
    return _c('div', {
      key: index,
      staticClass: "mb-7"
    }, [_vm.tasks[occurrence].length > 0 ? _c('div', [_c('v-toolbar', {
      class: 'text font-weight-bold ' + _vm.colorize(index + 1),
      attrs: {
        "fixed": "",
        "dense": "",
        "dark": "",
        "flat": "",
        "rounded": ""
      }
    }, [_vm._v(_vm._s(occurrence.toString().toUpperCase()) + " ")]), _c('v-data-table', {
      staticClass: "elevation-1 rounded-lg mt-2",
      attrs: {
        "dense": "",
        "headers": _vm.headers,
        "items": _vm.tasks[occurrence],
        "loading": _vm.loading,
        "single-select": false,
        "footer-props": {
          'items-per-page-options': [20, 50, 100]
        }
      },
      scopedSlots: _vm._u([{
        key: `item.title`,
        fn: function (_ref) {
          var item = _ref.item;
          return [_c('task-item', {
            attrs: {
              "item": item
            },
            on: {
              "onValueChange": function ($event) {
                return _vm.onValueChange($event);
              }
            }
          })];
        }
      }, {
        key: `item.time`,
        fn: function (_ref2) {
          var item = _ref2.item;
          return [_vm._v(" " + _vm._s(_vm._f("parseTimeToLocal")(item.time)) + " ")];
        }
      }, {
        key: `item.responsibleUsers`,
        fn: function (_ref3) {
          var item = _ref3.item;
          return _vm._l(item.responsibleUsers, function (user, index) {
            var _user$responsibleUser, _user$responsibleUser2;
            return _c('v-chip', {
              key: index,
              staticClass: "font-weight-bold cliporder mr-1",
              class: _vm.currentUserName === ((_user$responsibleUser = user.responsibleUser.fullName) !== null && _user$responsibleUser !== void 0 ? _user$responsibleUser : user.responsibleUser.name) ? 'greeny white--text' : '',
              staticStyle: {
                "min-width": "10px",
                "font-size": "12px",
                "min-height": "27px",
                "text-transform": "capitalize"
              },
              attrs: {
                "link": "",
                "x-small": ""
              }
            }, [_vm._v(" " + _vm._s((_user$responsibleUser2 = user.responsibleUser.fullName) !== null && _user$responsibleUser2 !== void 0 ? _user$responsibleUser2 : user.responsibleUser.name) + " ")]);
          });
        }
      }, {
        key: `item.signedBy`,
        fn: function (_ref4) {
          var _item$lastCompletedEv;
          var item = _ref4.item;
          return [_vm._v(" " + _vm._s(item.isCompleted ? item.lastCompletedEvent && ((_item$lastCompletedEv = item.lastCompletedEvent.user.fullName) !== null && _item$lastCompletedEv !== void 0 ? _item$lastCompletedEv : item.lastCompletedEvent.user.name) : "") + " ")];
        }
      }, {
        key: `item.actions`,
        fn: function (_ref5) {
          var item = _ref5.item;
          return [_vm.isBackOfficeAdmin ? _c('div', [_c('v-btn', {
            attrs: {
              "icon": ""
            },
            on: {
              "click": function ($event) {
                return _vm.view(item);
              }
            }
          }, [_c('v-icon', {
            attrs: {
              "color": "accent"
            }
          }, [_vm._v("mdi-eye")])], 1), _c('v-btn', {
            attrs: {
              "icon": ""
            },
            on: {
              "click": function ($event) {
                return _vm.openEditModal(item);
              }
            }
          }, [_c('v-icon', {
            attrs: {
              "color": "third"
            }
          }, [_vm._v("mdi-pencil")])], 1), _c('v-btn', {
            attrs: {
              "icon": ""
            },
            on: {
              "click": function ($event) {
                return _vm.deleteTask(item.id);
              }
            }
          }, [_c('v-icon', {
            attrs: {
              "color": "primary"
            }
          }, [_vm._v("mdi-delete")])], 1)], 1) : _vm._e()];
        }
      }], null, true),
      model: {
        value: _vm.selected,
        callback: function ($$v) {
          _vm.selected = $$v;
        },
        expression: "selected"
      }
    })], 1) : _vm._e()]);
  }), _vm.loading ? _c('v-progress-linear', {
    attrs: {
      "indeterminate": "",
      "color": "#f4846e"
    }
  }) : _vm._e(), _vm.editItem ? _c('detail-dialog', {
    attrs: {
      "item": _vm.editItem,
      "isDialogOpen": _vm.isDetailDialogOpen
    },
    on: {
      "onClose": function ($event) {
        _vm.isDetailDialogOpen = false;
        _vm.editItem = null;
      }
    }
  }) : _vm._e()], 2);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "v-toolbar__title"
  }, [_c('h4', {
    staticClass: "black--text"
  }, [_vm._v("Tasks")])]);

}]

export { render, staticRenderFns }
<template>
  <div class="d-flex align-center m-0 p-0">
    <v-checkbox
      style="margin: 0; padding: 0"
      v-model="item.isCompleted"
      :hide-details="true"
      @click="confirmTaskCompleteChange"
    ></v-checkbox>
    <span :style="{ 'text-decoration': item.isCompleted ? 'line-through' : 'none' }">{{
      item.title
    }}</span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["item"],
  computed: {
    ...mapGetters({
      user: "auth/user"
    })
  },
  methods: {
    confirmTaskCompleteChange() {
      if (this.item.lastCompletedEvent && this.item.lastCompletedEvent.user) {
        this.item.lastCompletedEvent.user.fullName = this.user.fullName;
      }

      const value = this.item.isCompleted;
      this.$emit("onValueChange", { value, id: this.item.id });
    }
  }
};
</script>

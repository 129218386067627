var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-center m-0 p-0"
  }, [_c('v-checkbox', {
    staticStyle: {
      "margin": "0",
      "padding": "0"
    },
    attrs: {
      "hide-details": true
    },
    on: {
      "click": _vm.confirmTaskCompleteChange
    },
    model: {
      value: _vm.item.isCompleted,
      callback: function ($$v) {
        _vm.$set(_vm.item, "isCompleted", $$v);
      },
      expression: "item.isCompleted"
    }
  }), _c('span', {
    style: {
      'text-decoration': _vm.item.isCompleted ? 'line-through' : 'none'
    }
  }, [_vm._v(_vm._s(_vm.item.title))])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "value": _vm.isDialogOpen,
      "transition": "dialog-top-transition",
      "max-width": "75%",
      "scrollable": false
    },
    on: {
      "click:outside": _vm.closeDialog
    }
  }, [_c('v-card', [_c('v-toolbar', {
    staticClass: "title",
    attrs: {
      "fixed": "",
      "color": "titlebg",
      "dense": "",
      "dark": ""
    }
  }, [_c('v-toolbar-title', [_vm._v("Task : " + _vm._s(_vm.item.title))]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('div', {
    staticClass: "px-4 py-4"
  }, [_c('v-row', [_c('v-col', [_c('v-card', {
    staticClass: "py-2 px-4 mb-4"
  }, [_c('h5', {
    staticClass: "mb-2"
  }, [_vm._v("Responsible Users")]), _vm._l(_vm.item && _vm.item.responsibleUsers, function (user, index) {
    var _user$responsibleUser;
    return _c('v-chip', {
      key: index,
      staticClass: "font-weight-semibold mr-2 mb-2",
      attrs: {
        "link": "",
        "medium": "",
        "color": "primary"
      }
    }, [_vm._v(" " + _vm._s((_user$responsibleUser = user.responsibleUser.fullName) !== null && _user$responsibleUser !== void 0 ? _user$responsibleUser : user.responsibleUser.name) + " ")]);
  })], 2), _c('v-card', {
    staticClass: "py-2 px-4 mb-4"
  }, [_c('h5', {
    staticClass: "mb-2"
  }, [_vm._v("Description")]), _c('p', {
    staticClass: "p-0 m-0"
  }, [_vm._v(" " + _vm._s(_vm.item && _vm.item.description) + " ")])])], 1), _c('v-col', [_c('v-card-text', {
    staticClass: "py-0 elevation-2 rounded-lg",
    staticStyle: {
      "max-height": "500px",
      "overflow": "auto"
    }
  }, [_c('v-timeline', {
    attrs: {
      "align-top": "",
      "dense": ""
    }
  }, _vm._l(_vm.item && _vm.item.events, function (event, index) {
    var _event$user$fullName;
    return _c('v-timeline-item', {
      key: event.id,
      attrs: {
        "color": index % 2 ? 'accent' : 'greeny',
        "small": ""
      }
    }, [_c('v-row', {
      staticClass: "pt-1"
    }, [_c('v-col', {
      attrs: {
        "cols": "3"
      }
    }, [_c('strong', [_vm._v(_vm._s(event.datetime))])]), _c('v-col', [_c('strong', [_vm._v(_vm._s((_event$user$fullName = event.user.fullName) !== null && _event$user$fullName !== void 0 ? _event$user$fullName : event.user.name))]), _c('div', {
      staticClass: "text-caption"
    }, [_vm._v(" " + _vm._s(event.type.split("_").join(" ")) + " ")])])], 1)], 1);
  }), 1)], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }